@define-mixin btn_bg_defaultSettings $bgColor1,$bgColor2,$txtColor {
  color:$txtColor;
  border: 1px solid $bgColor1;
  background-color:$bgColor2;
}

.btn_wrap  {
  display:block;
  padding:10px 30px;
  border-radius:5px;
}

.btn {
  font-size:1.5rem;
  display:inline-block;
  font-weight:700;
  transition:background-color 0.3s, color 0.3s;
}

.btn_bg_accent {
  @mixin btn_bg_defaultSettings var(--whiteColor),var(--accentColor),var(--whiteColor);
  &:hover {
    background-color:var(--whiteColor);
    color:var(--accentColor);
  }
}

.btn_bg_main {
  @mixin btn_bg_defaultSettings var(--whiteColor),rgba(0,0,0,0.8),var(--whiteColor);
  &:hover {
    border:1px solid var(--mainColor);
    background-color: var(--mainColor);
    color:var(--whiteColor);
  }
}

.btn_bg_more {
  @mixin btn_bg_defaultSettings var(--whiteColor),rgba(0,0,0,0.8),var(--whiteColor);
  &:hover {
    border:1px solid var(--mainColor);
    background-color: var(--mainColor);
    color:var(--whiteColor);
  }
}