.footWrap {
  background:var(--mainColor);
  padding-top:50px;
  ul {
    @mixin blockCenter;
  }
  .copyBlock {
    @mixin blockCenter;
    text-align:center;
    color:var(--backColor);
    padding:20px 0;
  }
}


@media only screen and (min-width: 960px) {
  .footWrap {
    
    ul {
      @mixin flexBetWeen;
      padding:20px 200px;
      border-top:1px solid var(--backColor);
      
      li {
        a {
          font-size:1.3rem;
          color:var(--backColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 959px) {

}
