
@define-mixin topBottomSandWitch {
    padding: 10px;
    color: vaa(--whiteColor);
    transition: color 0.3s;

    &::before, &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: #fff;
      content: '';
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;
      transform: translateY(-10px);
    }
    
    &::before {
      top: 0;
      transform: translateY(-10px);
    }
    
    &::after {
      bottom: 0;
      transform: translateY(10px);
    }
    &:hover,
    &:focus {
      color: #fff;
    }
    
    &:hover::before,
    &:focus::before {
      opacity: 1;
      transform: translateY(-5px);
    }

    &:hover::after,
    &:focus::after {
      opacity: 1;
      transform: translateY(5px);
    }
}
