@media only screen and (min-width: 960px) {
    .headWrap {
        padding:100px 0 20px 0;
        margin-bottom:50px;
        background:var(--mainColor);
    
        h1.logo {
            img {
                width:180px;
                margin-bottom:50px;
            }
        }
    
        .navWrap {
            @mixin blockCenter;
    
            ul {
                @mixin flexBetWeen;
                align-items: baseline;
                padding:20px 100px;
                
                li {
                    a {
                        font-size:1.7rem;
                        color:var(--backColor);
                        @mixin topBottomSandWitch;
                        &.submenu {
                            font-size:1.4rem !important;
                            margin-top:15px;
                        }
                    }

                }
            }
        }
    }
}