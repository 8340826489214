@custom-media --highPc-width (min-width:1401px);
@custom-media --middlePc-width only screen adn (min-width: 1200px) and (max-width:1400px);
@custom-media --lowPc-width only screen and (min-width: 1100px) and (max-width:1199px);
@custom-media --vgaPc-width only screen and (min-width: 960px) and (max-width:1099px);

@define-mixin centerBoxMixin {
  margin:0 auto;
  @media (--highPc-width) { width:75%; }
  @media (--middlePc-width){ width:85%; }
  @media (--lowPc-width) { width:90%; a { font-size:1.4rem !important; } }
  @media (--vgaPc-width) { width:98%; a { font-size:1.2rem !important; } }
}



/* about css */ 
@define-mixin aboutSettings {
  .index_aboutimg {
    border-radius:100px;
    margin-bottom:30px;
  }
  dl {
    dt {
      font-size:1.8rem;
    }
    dd {
      font-size:1.8rem;
      &:first-of-type {
        color:#b1b1b1;
        padding-bottom:30px;
      }
    }
  }
  i {
    transition:transform 0.5s, color;
    transform:scale(1.2);
    color:#757575;
    &:hover {
      transform:scale(2.2);
      color:#00aced;
    }
  }
}



@define-mixin blockCenter {
  margin:0 auto;
  @media only screen and (min-width: 1401px) { width:60%; }
  @media only screen and (min-width: 1200px) and (max-width:1400px) { width:85%; }
  @media only screen and (min-width: 1100px) and (max-width:1199px) { width:90%; a { font-size:1.4rem !important; } }
  @media only screen and (min-width: 960px) and (max-width:1099px) { width:98%; a { font-size:1.2rem !important; } }
  @media only screen and (max-width: 959px) { width:98%; }

}

/* @define-mixin mainH2Style {
  font-size:3rem;
  color:$main_color;
  font-weight:700;
  margin-bottom:30px;
  border-bottom:3px solid #cccccc;
  display:inline-block;
  position:relative;
  &::before {
    content:'';
    position:absolute;
    bottom:-3px;
    left:0;
    width:40%;
    border-bottom:3px solid $main_color;
  }  
} */

/* 
@define-mixin blockCenter() {
  margin:0 auto;
  @media only screen and (min-width: 1600px) { width:80%; }
  @media only screen and (min-width: 1200px) and (max-width:1599px) { width:90%; }
  @media only screen and (min-width: 960px) and (max-width:1199px) { width:95%; }
} */

@define-mixin mainH2Style {
  @media only screen and (min-width: 1600px) { font-size:3rem; }
  @media only screen and (min-width: 1200px) and (max-width:1599px) { font-size:2.5rem; }
  @media only screen and (min-width: 960px) and (max-width:1199px) { font-size:2.5rem; }
  color: var(--mainColor);
  font-weight:700;
  margin:0 auto 30px auto;
  border-bottom:3px solid #cccccc;
  display:inline-block;
  position:relative;
  &::before {
    content:'';
    position:absolute;
    bottom:-3px;
    left:0;
    width:40%;
    border-bottom:3px solid var(--mainColor);
  }  
}

@define-mixin mainH3Style {
  font-size:1.5rem;
  font-weight:700;
  border-left: 5px solid var(--accsentColor);
  padding-left:10px;
  margin-bottom:10px;  
}
