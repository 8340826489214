/* @import './_movieContents_pc';
@import './_movieContents_sp';
 */

.loginWrap {
  .loginBlock {
    @mixin blockCenter;
    padding:50px 100px;

    span {
      font-size:1.5rem;
      display:inline-block;
      position:relative;
      margin-right:100px;
      &::before {
        content:'';
        position:absolute;
        width:130%;
        right:-48px;
        bottom:0;
        border-bottom:1px solid var(--mainColor);
      }
      &::after {
        content:'';
        position:absolute;
        width:30px;
        right:-53px;
        bottom:10px;
        border-bottom:1px solid var(--mainColor);
        transform: rotate(45deg);
      }
    }

    input {
      margin-right:20px;
      width:100px;
      line-height:30px;
    }
    input[type="submit"] {
      border:1px solid var(--mainColor);
      background:none;
      line-height:30px;
      display:inline-block;
      cursor:pointer;
      &:hover {
        background-color:var(--mainColor);
        color:#fff;
      }

    }

  }
}

.topBack {
  @mixin blockCenter;
  text-align:center;

}


.errorbox {
  @mixin blockCenter;
  padding:10px 0;
  text-align:center;
  font-size:1.5rem;
  color:#f00;
}