@import './_headContents_pc';
/* @import './_headContents_sp'; */

.headWrap {
    padding:100px 0 30px 0;
    background:var(--mainColor);

    h1.logo {
        text-align:center;
    }

    .navWrap {
        @mixin blockCenter;
        ul {
            li {
                @mixin winFontCrean;
                font-family:Josefin Sans;
                text-transform:uppercase;
            }
        }
    }
}