/* @import './_newsContents_pc';
@import './_newsContents_sp';
 */
.policyWrap {
  @mixin blockCenter;
  h1 {
    font-size:2rem;
    font-weight:700;
    text-transform:uppercase;
    margin-bottom:50px;
  }

  ol {
    padding: 0 100px;
    margin-bottom:100px;
    li {
      font-size:1.4rem;
      letter-spacing:0.2em;
      line-height:1.75;
      list-style-type: decimal-leading-zero;
      margin-bottom:30px;
    }
  }
}