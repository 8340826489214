@media only screen and (min-width: 960px) {
  .lessonWrap {
    position:relative;
    height:auto;
    &::before, &::after {
      content:"";
      display:block;
      position:absolute;
      width:200px;
      height:100px;
    }

    &::before {
      left:0;
      top:0;
      border-top:3px double var(--mainColor);
      border-left:3px double var(--mainColor);
    }
    &::after {
    }

    .flexWrap {
      padding-bottom:100px;
      ul {
        margin-bottom:70px;
        @mixin flexBetWeen;
        li {
          width:30%;
          img {
            width:100%;
          }
        }
      }
      p {
        font-size:1.4rem;
        line-height:1.75;
        letter-spacing: 0.2em;
      }
    }


    .headBlock {
      margin-bottom:50px;
      padding:50px 0 0 50px;
      h1 {
        text-transform:uppercase;
        font-family:Josefin Sans;
        font-weight:700;
        font-size:2.8rem;
        display:inline-block;
      }
      dl {
        display:inline-block;
        font-size:1.2rem;
        padding-left:50px;
      }
    }

    .textBlock {
      padding:50px 0 0 50px;
      h2 {
        font-size:1.8rem;
        margin-bottom:30px;
      }

      p {
        font-size:1.4rem;
      }
    }
    .imgBlock {
      position:absolute;
      right:0;
      top:0;
      width:30%;
      img {
        width:100%;
        height:100%;
      }
    }

    .buttonBlock {
      padding:50px 0 80px 50px;
      
      text-align:left;
      a {
        display:inline-block;
        margin:0 auto;
        padding:10px 30px;
        text-align:center;
      }
    }
    
  }
}
