@import './_aboutContents_pc';
/* @import './_aboutContents_sp'; */

/*

Global design
index > HeadContents ( swiper.js area )

*/

.aboutWrap {
  @mixin blockCenter;
  padding:70px 0;

  h2 {
    @mixin mainH2Style;
  }
  p {
    font-size:1.4rem;
    margin-bottom:50px;
  }
}

.aboutAbdWrap {
  @mixin blockCenter;


  p {
    line-height:1.75;
    letter-spacing: 0.2em;
  }

  h1 {
    font-size:2rem;
    font-weight:700;
    text-transform:uppercase;
    margin-bottom:100px;
  }

  .plasterPhoto {
    background:transparent url('../assets/images/plaster/photo01.jpg') no-repeat right top;
    background-size:contain;
    padding-top:100px;
    margin-bottom:150px;
    p {
      font-size:1.4rem;
    }
  }

  .desctiption {
    background:transparent url('../assets/images/system/flowerandaroma.svg') no-repeat left top;
    background-size:contain;
    min-height:180px;
    position:relative;
    h2 {
      position:relative;
      display:inline-block;
      left:-50px;
      top:10px;
      font-size:2.3rem;
      font-weight:700;
      transform:rotate(-10deg);
    }
    p {
      padding-left:250px;
      font-size:1.4rem;
    }
  }


  .badbox {
    background-size:30%;
    padding-top:100px;
    margin-bottom:150px;
    position:relative;
    span {
      &.logo {
        position:absolute;
        left:0;
        top:0;
        background:transparent url('./assets/images/system/logo.svg') no-repeat left top;
        width:200px;
        height:200px;
        background-size:cover;
        display:block;
      }
      &.as {
        position:absolute;
        left:0;
        top:160px;
        background:transparent url('./assets/images/system/as.svg') no-repeat left top;
        width:300px;
        height:100px;
        background-size:contain;
        display:block;
        transform: rotate(-14deg);

      }
    }
    p {
      font-size:1.4rem;
      margin-top:150px;
      margin-bottom:40px;
      border:1px solid #000;
      padding:10px 40px;
      display:inline-block;
    }
    img.svg {
      width:70%;
    }
  }




}