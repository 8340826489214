@media only screen and (min-width: 960px) {
  .aboutAbdWrap {
    background-image: url('./assets/images/system/bad.svg');
    background-repeat: no-repeat;
    background-position:28px 90px;
    background-size:30%;
    padding:70px 0;


  }
}